import React, { useRef, useState } from 'react';
import { orderBy } from 'lodash-es';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@digital-gov/ui-utils';
import { Options } from '@popperjs/core';
import { DropdownList } from 'componentsL/DropdownList';
import { DropdownItem } from 'componentsL/DropdownList/DropdownItem';
import { OptionItem } from 'componentsL/DropdownList/types';
import s from './InfoSelect.module.scss';

const popperOptions: Partial<Options> = {
  strategy: 'fixed',
  placement: 'bottom',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    }
  ]
};

export interface InfoSelectProps {
  isOpen?: boolean;
  value?: string | null;
  options: OptionItem[] | null;
  onClose?: () => void;
  onChange?: (value: string) => void;
}

export const InfoSelect = ({ isOpen, value, options, onClose, onChange }: InfoSelectProps) => {
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownMenuRef.current, onClose);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(dropdownMenuRef.current, popperElement, {
    ...popperOptions
  });

  const sortedOptions = orderBy(options, ['label'], ['asc']);

  const handleChange = (value: string) => {
    onChange?.(value);
    onClose?.();
  };

  return (
    <div ref={dropdownMenuRef} className={s.InfoSelect}>
      {isOpen && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            width:
              dropdownMenuRef.current?.offsetWidth && dropdownMenuRef.current?.offsetWidth < 256
                ? 256
                : dropdownMenuRef.current?.offsetWidth
          }}
          {...attributes.popper}
          className={s.InfoSelect__dropdown}>
          <DropdownList>
            {sortedOptions?.map((option) => (
              <DropdownItem
                key={option.value}
                value={option.label}
                isActive={option.value === value}
                onClick={(e) => {
                  e.preventDefault();
                  handleChange(option.value);
                }}
              />
            ))}
          </DropdownList>
        </div>
      )}
    </div>
  );
};

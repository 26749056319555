import { memo, useRef, useState } from 'react';
import clsx from 'clsx';
import { orderBy } from 'lodash-es';
import { usePopper } from 'react-popper';
import { useClickOutside, pluralize, formatNumber } from '@digital-gov/ui-utils';
import { Options } from '@popperjs/core';
import { DropdownList } from 'componentsL/DropdownList';
import { DropdownItem } from 'componentsL/DropdownList/DropdownItem';
import { OptionItem } from 'componentsL/DropdownList/types';
import { ISortedColumn, SelectedIndicatorType } from '../../types';
import { ReactComponent as ArrowDownIcon } from './Type=Arrow-down.svg';
import { ReactComponent as SortIcon } from './Type=General-sort.svg';
import s from './TableCellHeaderSelect.module.scss';

export interface TableCellHeaderSelectProps {
  className?: string;
  title?: string;
  subTitle?: string;
  maxValue?: number | null;
  sort: ISortedColumn['sort'];
  options: OptionItem[] | null;
  isOpen?: boolean;
  onLeftIconClick?: () => void;
  dropdownClose?: () => void;
  onOptionClick?: (value: string) => () => void;
  selectedIndicator?: SelectedIndicatorType | null;
  onColumnSortIcon?: () => void;
}

export const popperOptions: Partial<Options> = {
  strategy: 'fixed',
  placement: 'bottom',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    }
  ]
};

export const TableCellHeaderSelect = memo((props: TableCellHeaderSelectProps) => {
  const {
    subTitle,
    maxValue,
    selectedIndicator,
    onColumnSortIcon,
    title,
    sort,
    onOptionClick,
    className,
    isOpen,
    onLeftIconClick,
    dropdownClose,
    options
  } = props;

  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownMenuRef.current, dropdownClose);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(dropdownMenuRef.current, popperElement, {
    ...popperOptions
  });

  const sortedOptions = orderBy(options, ['label'], ['asc']);

  return (
    <div ref={dropdownMenuRef} className={clsx(s.TableCellHeaderSelect, className)}>
      <ArrowDownIcon
        onClick={onLeftIconClick}
        className={clsx(
          s.TableCellHeaderSelect__icon,
          isOpen && s.TableCellHeaderSelect__icon_isOpen,
          s.TableCellHeaderSelect__leftIcon
        )}
      />

      <div className={s.TableCellHeaderSelect__content}>
        <p className={s.TableCellHeaderSelect__title}>{title}</p>
        {subTitle && <p className={s.TableCellHeaderSelect__subTitle}>{subTitle}</p>}
        {typeof maxValue === 'number' && (
          <p className={s.TableCellHeaderSelect__subTitle}>
            (Max {formatNumber(maxValue, 2, true)} {pluralize(maxValue, 'балл', 'балла', 'баллов')})
          </p>
        )}
      </div>
      <SortIcon
        onClick={onColumnSortIcon}
        className={clsx(
          s.TableCellHeaderSelect__icon,
          s.TableCellHeaderSelect__rightIcon,
          s[`TableCellHeaderSelect__icon_${sort}`]
        )}
      />

      {isOpen && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            width:
              dropdownMenuRef.current?.offsetWidth && dropdownMenuRef.current?.offsetWidth < 256
                ? 256
                : dropdownMenuRef.current?.offsetWidth
          }}
          {...attributes.popper}
          className={s.TableCellHeaderSelect__dropdown}>
          <DropdownList>
            {sortedOptions?.map((option) => (
              <DropdownItem
                key={option.value}
                value={option.label}
                isActive={option.value === String(selectedIndicator?.indicatorId)}
                onClick={onOptionClick?.(option.value)}
              />
            ))}
          </DropdownList>
        </div>
      )}
    </div>
  );
});

TableCellHeaderSelect.displayName = 'TableCellHeaderSelect';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Spoiler } from '../../../Spoiler';
import { FaqContext } from '../../faq-context';
import s from './QAQuestion.module.scss';

export interface QAItemProps {
  id: number;
  question: string;
  answer: string;
}

export const QaQuestion = ({ id, question, answer }: QAItemProps) => {
  const faq = useContext(FaqContext);

  const [collapsed, setCollapsed] = useState(true);

  const handleClick = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  useEffect(() => {
    setCollapsed(faq.query.length === 0 || !faq.getQuestionVisibility(id));
  }, [faq, faq.query, setCollapsed]);

  return (
    <Spoiler
      collapsed={collapsed}
      onTitleClick={handleClick}
      className={s.QaQuestion}
      classContent={s.QaQuestion__content}
      title={
        <div
          className={clsx(s.QaQuestion__question, collapsed || s.QaQuestion__question_active)}
          {...faq.props(faq.SearchArea.Question, id)}>
          {question}
        </div>
      }>
      <div
        className={s.QaQuestion__answer}
        dangerouslySetInnerHTML={{ __html: answer }}
        {...faq.props(faq.SearchArea.Answer, id)}
      />
    </Spoiler>
  );
};

import React from 'react';
import { AdvancedTable } from '../../_components/AdvancedTable';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const iJkh = components[0]?.value;
  const iKn = components[1]?.value;
  const vKn = components[2]?.value;
  const iBr = components[3]?.value;
  const vBr = components[4]?.value;
  const iKu = components[5]?.value;
  const vKu = components[6]?.value;
  const iPd = components[7]?.value;
  const vPd = components[8]?.value;
  const iIpu = components[9]?.value;
  const vIpu = components[10]?.value;
  return (
    <SymbolsGrid columns={4}>
      {/*---*/}
      <SymbolDetails value={iJkh} unit={'%'} description={'Значение показателя «Доля внесённой в ГИС ЖКХ информации»'}>
        <Symbol sub={'ЖКХ'}>И</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={iKn}
        unit={'%'}
        description={
          <>
            Значение подпоказателя «Доля внесённой <br />в ГИС ЖКХ информации по кадастровым номерам»
          </>
        }>
        <Symbol sub={'КН'}>И</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={vKn}
        unit={'%'}
        description={'Вес подпоказателя «Доля внесённой в ГИС ЖКХ информации по кадастровым номерам»'}>
        <Symbol sub={'КН'}>В</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={iBr}
        unit={'%'}
        description={
          <>
            Значение подпоказателя «Доля внесённой <br />в ГИС ЖКХ информации по банковским (платёжным) реквизитам
            поставщиков»
          </>
        }>
        <Symbol sub={'БР'}>И</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={vBr}
        unit={'%'}
        description={
          'Вес подпоказателя «Доля внесённой в ГИС ЖКХ информации по банковским (платёжным) реквизитам поставщиков»'
        }>
        <Symbol sub={'БР'}>В</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={iKu}
        unit={'%'}
        description={
          <>
            Значение подпоказателя «Доля внесённой <br />в ГИС ЖКХ информации по коммунальным услугам»
          </>
        }>
        <Symbol sub={'КУ'}>И</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={vKu}
        unit={'%'}
        description={'Вес подпоказателя «Доля внесённой в ГИС ЖКХ информации по коммунальным услугам»'}>
        <Symbol sub={'КУ'}>В</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={iPd}
        unit={'%'}
        description={
          <>
            Значение подпоказателя «Доля внесённой <br />в ГИС ЖКХ информации по платёжным документам»
          </>
        }>
        <Symbol sub={'ПД'}>И</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={vPd}
        unit={'%'}
        description={'Вес подпоказателя «Доля внесённой в ГИС ЖКХ информации по платёжным документам»'}>
        <Symbol sub={'ПД'}>В</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={iIpu}
        unit={'%'}
        description={
          <>
            Значение подпоказателя «Доля внесённой <br />в ГИС ЖКХ информации по индивидуальным приборам учёта
            коммунальных ресурсов»
          </>
        }>
        <Symbol sub={'ИПУ'}>И</Symbol>
      </SymbolDetails>
      {/*---*/}
      <SymbolDetails
        value={vIpu}
        unit={'%'}
        description={
          'Вес подпоказателя «Доля внесённой в ГИС ЖКХ информации по индивидуальным приборам учёта коммунальных ресурсов»'
        }>
        <Symbol sub={'ИПУ'}>В</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    /* eslint-disable */
    <AdvancedTable
      rows={[
        { cells: ['Оценка, балл', 'Значение'], isHeader: true },
        { cells: ['0,0', ['И', { text: 'ЖКХ', align: 'sub' }, ' < 50%']] },
        { cells: ['0,5', ['50% ≤ И', { text: 'ЖКХ', align: 'sub' }, ' < 70%']] },
        { cells: ['1,0', ['70% ≤ И', { text: 'ЖКХ', align: 'sub' }, ' < 80%']] },
        { cells: ['1,5', ['80% ≤ И', { text: 'ЖКХ', align: 'sub' }, ' < 90%']] },
        { cells: ['2,0', ['90% ≤ И', { text: 'ЖКХ', align: 'sub' }, ' < 100%']] }
      ]}
    />
    /* eslint-enable */
  );
}

export function GisJkh() {
  return (
    <IndicatorPage
      regionScope
      download={'ГИС ЖКХ.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}

import { memo, useCallback } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { PageError } from 'componentsL/ErrorMessage';
import { Loader } from 'componentsL/Loader';
import { RegionRatingType } from 'store/graphql';
import { useRegionsFilter } from './hooks/useRegionsFilter';
import { RegionsFilters, ViewMode } from './RegionsFilters';
import { RegionsMap } from './RegionsMap';
import { RegionsTableCompact } from './RegionsTableCompact';
import { RegionsTableFull } from './RegionsTableFull';
import s from './RegionsView.module.scss';

export interface RegionsViewProps {
  className?: string;
  regionalRating?: RegionRatingType;
  loading?: boolean;
  error?: ApolloError;
}

export const RegionsView = memo((props: RegionsViewProps) => {
  const { className, regionalRating, loading, error } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = (searchParams.get('mode') || 'table-compact') as ViewMode;

  const setMode = useCallback(
    (mode: ViewMode) => {
      setSearchParams({ mode }, { replace: true });
    },
    [setSearchParams]
  );

  const { filteredData, filter, setFilter } = useRegionsFilter(mode, regionalRating);

  if (loading || error) {
    return (
      <div className={clsx(s.RegionsView__screen, className)}>
        <div className={s.RegionsView__screenContent}>{loading ? <Loader /> : <PageError />}</div>
      </div>
    );
  }

  return (
    <>
      <RegionsFilters
        className={s.RegionsView__filters}
        regionalRating={regionalRating}
        filter={filter}
        setFilter={setFilter}
        mode={mode}
        onSetMode={setMode}
      />

      {(() => {
        if (mode === 'map') {
          return <RegionsMap regionalRating={regionalRating} filter={filter} />;
        } else {
          if (filteredData?.regions.length === 0) {
            return <p className={s.RegionsView__noData}>Ничего не найдено</p>;
          }

          if (mode === 'table-full') {
            return (
              <RegionsTableFull className={className} regionalRating={regionalRating} filteredData={filteredData} />
            );
          }

          if (mode === 'table-compact') {
            return <RegionsTableCompact className={className} filteredData={filteredData} />;
          }
        }
      })()}
    </>
  );
});

RegionsView.displayName = 'RegionsView';

import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@digital-gov/ui-utils';
import { ButtonLink, ButtonLinkType } from 'components/Button';
import { Spinner, SpinnerKind } from 'components/Spinner';
import { Icon, IconChevronDown, IconSize } from 'componentsL/Icon';
import { Tooltip } from 'componentsL/Tooltip';
import { basename } from 'store/config';
import { IndicatorMethodologyType, useGetMethodologyUrlLazyQuery } from 'store/graphql';
import s from './CardDownloadsBlock.module.scss';

type CardDownloadsBlockProps = {
  indicatorId?: number;
  methodologies?: IndicatorMethodologyType[];
  links: { label: string; filename?: string | null; path?: string | null }[];
};

export const CardDownloadsBlock = ({ indicatorId, methodologies, links }: CardDownloadsBlockProps) => {
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [targetNode, setTargetNode] = useState<HTMLDivElement | null>(null);

  const [getDownloadUrl, { loading }] = useGetMethodologyUrlLazyQuery({
    fetchPolicy: 'network-only'
  });
  const handleDownload = async () => {
    if (methodologies?.length && indicatorId) {
      const linkNode = document.createElement('a');

      const { data } = await getDownloadUrl({
        variables: { entityId: indicatorId, scope: methodologies[0].scope }
      });

      const downloadedURL = data?.fgiskiFilePresignedUrl;
      if (!downloadedURL) {
        console.error('Ошибка запроса URL на скачивание файла');
        return;
      }

      linkNode.href = downloadedURL;
      linkNode.click();
    }
  };
  const methodologyDownload = methodologies && methodologies.length > 0;
  useClickOutside(userMenuRef.current, () => setMenuOpen(false));
  const validLinks = links.filter((l) => l.filename != null);
  return (
    <div ref={userMenuRef} className={s.CardDownloadsBlock}>
      <div
        ref={setTargetNode}
        className={clsx(s.CardDownloadsBlock__row, menuOpen ? s.CardDownloadsBlock__open : null)}
        onClick={() => setMenuOpen((prevState) => !prevState)}>
        <Icon
          className={clsx(s.CardDownloadsBlock__menuArrow, {
            [s.CardDownloadsBlock__menuArrow_active]: menuOpen
          })}
          icon={IconChevronDown}
          size={IconSize.Small}
        />
        Скачать
      </div>
      {menuOpen && (
        <Tooltip
          classes={{ content: s.Tooltip__content }}
          showArrow={false}
          targetNode={targetNode}
          tooltipOptions={{
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0]
                }
              }
            ]
          }}>
          <div className={s.Tooltip__links}>
            {validLinks.map((link, iLink) => (
              <div key={`vl_${iLink}`} className={s.Tooltip__link}>
                <ButtonLink
                  key={iLink}
                  download
                  type={ButtonLinkType.Bright}
                  href={encodeURI(`${basename}/indicators/${link.path ? link.path + '/' : ''}${link.filename}`)}>
                  {link.label}
                </ButtonLink>
              </div>
            ))}
            {methodologyDownload && (
              <div className={s.Tooltip__link}>
                {loading && <Spinner kind={SpinnerKind.Inline} />}
                {!loading && (
                  <ButtonLink type={ButtonLinkType.Bright} onClick={handleDownload}>
                    Методика
                  </ButtonLink>
                )}
              </div>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

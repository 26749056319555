import React from 'react';
import { AdvancedTable } from '../../_components/AdvancedTable';
import { IndicatorPage } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const R = components[0]?.value; // 234 - R/P
  const RR = components[1]?.value; // 235 - Rr/Pr
  const k = components[2]?.value ?? 0.5; // 236 - k TODO
  const I = components[3]?.value; // 237 - I
  return (
    <SymbolsGrid columns={2} largeGap>
      <SymbolDetails
        value={I}
        description={
          <>Значение показателя «Готовность субъектов Российской Федерации к переходу на использование ЕЦП «ГосТех»</>
        }>
        <Symbol color={'#55D666'} square>
          I
        </Symbol>
      </SymbolDetails>
      <SymbolDetails value={R} description={'Наличие утвержденного регионального плана мероприятий'}>
        <Symbol>R</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={RR}
        description={
          'Реализация контрольных точек в установленные в соответствии с утвержденным региональным планом мероприятий сроки'
        }>
        <Symbol sub={'r'}>R</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={k}
        description={
          <>
            Понижающий коэффициент (константа = 0,5) за нарушение сроков по реализации контрольных точек, установленных
            в соответствии с утвержденным региональным планом мероприятий
          </>
        }>
        <Symbol>k</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    /* eslint-disable */
    // prettier-ignore
    <AdvancedTable
      rows={[
        { cells: ['Оценка, балл', 'Критерий'], isHeader: true },
        { cells: ['0', 'Региональный план мероприятий по созданию и развитию ГИС на ЕЦП «ГосТех» не утвержден/создание и развитие регионального сервиса не включено в план мероприятий ППК'] },
        { cells: ['1', 'Региональный план мероприятий по созданию и развитию ГИС на ЕЦП «ГосТех» утвержден/создание и развитие регионального сервиса включено в план мероприятий ППК'] },
        { cells: ['При наличии утвержденного регионального плана мероприятий'], isInTableCustomHeader: true },
        { cells: ['1', <>В соответствии с утвержденным региональным планом мероприятий контрольные точки реализованы <br/>в установленные сроки</>], reactNodes: true },
        { cells: ['При включении создания и развития регионального сервиса в План мероприятий ППК'], isInTableCustomHeader: true },
        { cells: ['1', 'В соответствии с утвержденным планом мероприятий ППК контрольные точки реализованы в установленные сроки'] }
      ]}
    />
    /* eslint-enable */
  );
}

export function SwitchToGovTech() {
  return (
    <IndicatorPage
      regionScope
      download={'Переход на ЕЦП ГосТех.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
    />
  );
}

import React from 'react';
import { Button, ButtonVariant } from 'componentsL/Button';
import { appBuild } from 'store/appBuild';
import { ServicesMenu } from './ServicesMenu';
import s from './Footer.module.scss';

const email = 'support@eskigov.ru';

export const Footer = () => {
  return (
    <footer className={s.Footer}>
      <div className={s.Footer__content}>
        <div className={s.Footer__siteInfo}>
          <div className={s.Footer__contacts}>
            <Button Component={'a'} href={`mailto:${email}`} variant={ButtonVariant.LinkPrimary}>
              {email}
            </Button>
          </div>
          <div className={s.Footer__appBuild}>
            © Рейтинги РЦТ. {new Date().getFullYear()}. Сборка {appBuild}
          </div>
        </div>

        <div className={s.Footer__servicesMenu}>
          <ServicesMenu />
        </div>
      </div>
    </footer>
  );
};

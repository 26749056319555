import React, { useCallback, useEffect, useState } from 'react';
import { useDebounceCallback } from '@digital-gov/ui-utils';
import { Button } from 'components/Button';
import { SearchInput } from 'components/Input';
import { Modal } from 'components/Modal';
import { PageLoader } from 'components/PageLoader';
import {
  RctScopeEnum,
  useChangeIndicatorResponsibleMutation,
  useIndicatorResponsiblesQuery,
  useSearchResponsibleByEmailLazyQuery
} from 'store/graphql';
import { IndicatorObjectType, repairFioString, ResponsibleType } from '../_utils';
import { ResponsibleWithId } from '../_utils/responsible.type';
import { Divider } from './_components/Divider';
import { Indicator } from './_components/Indicator';
import { Responsible } from './_components/Responsible';
import s from './ChangeResponsible.module.scss';

export interface ChangeResponsibleProps {
  scope: 'frct' | 'rrct';
  scopeId: number;
  indicatorObject: IndicatorObjectType;
  refetchScopeData?: () => void;
  currentResponsibles: ResponsibleType[];
  onError: () => void;
  onSuccess: () => void;
  onClose: () => void;
}

export function ChangeResponsible({
  scope,
  scopeId,
  indicatorObject,
  currentResponsibles,
  onSuccess,
  onError,
  onClose,
  refetchScopeData
}: ChangeResponsibleProps) {
  const [changeIndicatorResponsible, { loading: changeLoading }] = useChangeIndicatorResponsibleMutation();
  const [searchByEmailQuery] = useSearchResponsibleByEmailLazyQuery();
  const {
    data: responsiblesData,
    loading: initLoading,
    refetch: refetchIdicatorResponsibles
  } = useIndicatorResponsiblesQuery({
    variables: {
      indicatorId: indicatorObject.indicatorId,
      scope: scope === 'frct' ? RctScopeEnum.Frct : RctScopeEnum.Rrct,
      scopeId
    }
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [resultByEmail, setResultByEmail] = useState<{ fullName: string; email: string; userId: number } | null>(null);
  const [notification, setNotification] = useState<string | null>(null);
  const [_currentResponsibles, setCurrentResponsibles] = useState<ResponsibleWithId[]>([]);

  // Получение отвественных в более полной форме (с userId)
  useEffect(() => {
    if (responsiblesData?.rating.indicatorResponsible) {
      const validEntries: ResponsibleWithId[] = responsiblesData.rating.indicatorResponsible.filter(
        (r) => r.userId && r.fullName && r.email
      ) as ResponsibleWithId[];
      const currentResponsibleNames = currentResponsibles.map((r) => repairFioString(r.fullName));
      const filteredValidEntries = validEntries.filter((v) => currentResponsibleNames.includes(v.fullName));
      setCurrentResponsibles(filteredValidEntries);
    }
  }, [currentResponsibles, responsiblesData]);

  // хендлер на отвязывание ответственного (удаление из текущего списка)
  const handleUnlinkResponsible = useCallback(
    (fullName: string) => {
      return (() => {
        setCurrentResponsibles((responsibles) => {
          return responsibles.filter((r) => r.fullName !== fullName);
        });
      })();
    },
    [setCurrentResponsibles]
  );

  // Хэндлинг инпута "поиска по email"
  const handleSearchInput = (query: string | null) => {
    setNotification(null);
    setResultByEmail(null);
    if (!query || query === '') {
      setSearchInput(null);
      setResultByEmail(null);
    } else {
      setSearchInput(query);
      onSearchInputChange(query);
    }
  };

  // Приторможенный обработчик, который спустя 500 мс после изменения инпута - отправляет запрос
  const onSearchInputChange = useDebounceCallback(async (value: string) => {
    await searchByEmailCallback(value);
  }, 500);

  // Коллбэк на поиск
  const searchByEmailCallback = useCallback(
    async (value: string) => {
      try {
        if (!value || !value.includes('@') || value.length > 320) {
          return;
        }
        const result = await searchByEmailQuery({
          variables: {
            email: value
          }
        });
        if (!result.data || result.error) {
          setResultByEmail(null);
          setNotification('Ошибка поиска.');
          return;
        }
        const responsible = result.data.rating.indicatorResponsible[0];
        if (responsible) {
          setResultByEmail({
            fullName: responsible.fullName,
            email: responsible.email ?? '',
            userId: responsible.userId
          });
          setNotification(null);
        } else {
          setNotification('Пользователь с данным email не найден.');
        }
      } catch (e) {
        setResultByEmail(null);
        return;
      }
    },
    [searchByEmailQuery, setNotification, setResultByEmail]
  );

  // хендлер сохранения результата поиска в текущем списке ответственных
  const handleSaveSearchResult = () => {
    if (resultByEmail) {
      setCurrentResponsibles((responsibles) => {
        if (responsibles.find((r) => r.fullName === resultByEmail.fullName)) {
          return [...responsibles];
        }
        return [
          ...responsibles,
          {
            fullName: resultByEmail.fullName,
            email: resultByEmail.email,
            userId: resultByEmail.userId
          }
        ];
      });
      setResultByEmail(null);
      setSearchInput('');
    }
  };

  const linkResponsiblesToIndicator = useCallback(async () => {
    try {
      setLoading(true);
      await changeIndicatorResponsible({
        variables: {
          input: {
            indicatorId: indicatorObject.indicatorId,
            scope: scope === 'frct' ? RctScopeEnum.Frct : RctScopeEnum.Rrct,
            responsibleIds: _currentResponsibles.map((r) => r.userId)
          }
        }
      });
      if (refetchScopeData) {
        await refetchScopeData();
      }
      if (refetchIdicatorResponsibles) {
        await refetchIdicatorResponsibles();
      }
      setLoading(false);
      onSuccess();
    } catch (e) {
      onError();
    }
  }, [
    changeIndicatorResponsible,
    indicatorObject,
    onError,
    onSuccess,
    scope,
    refetchScopeData,
    refetchIdicatorResponsibles,
    setLoading,
    _currentResponsibles
  ]);

  return (
    <Modal title={'Изменение ответственного по показателю'} onClose={onClose} width={624}>
      {initLoading && <PageLoader />}
      {!initLoading && (
        <div className={s.ChangeResponsible}>
          <Indicator name={indicatorObject.name} />
          <Divider />
          <div className={s.ChangeResponsible__controlGroup}>
            <div className={s.ChangeResponsible__searchInput}>
              <SearchInput
                value={searchInput ?? ''}
                onChange={handleSearchInput}
                placeholder={'Поиск по email'}
                dimmedPlaceholder
              />
              {resultByEmail && (
                <div className={s.ChangeResponsible__searchResult} onClick={handleSaveSearchResult}>
                  <Responsible data={resultByEmail} />
                </div>
              )}
            </div>
            {notification && <div className={s.ChangeResponsible__notification}>{notification}</div>}
            {_currentResponsibles &&
              _currentResponsibles.map((r, index) => {
                return (
                  <Responsible key={index} data={{ ...r }} handleUnlink={() => handleUnlinkResponsible(r.fullName)} />
                );
              })}
            <div className={s.ChangeResponsible__disclaimer}>
              Для создания нового ответственного представителя
              <a
                href={'mailto:support@eskigov.ru'}
                target={'_blank'}
                rel={'noreferrer'}
                className={s.ChangeResponsible__supportLink}>
                {' '}
                обратитесь в техподдержку
              </a>
            </div>
          </div>
          <Button
            disabled={changeLoading || loading}
            loading={changeLoading || loading}
            className={s.ChangeResponsible__addButton}
            onClick={linkResponsiblesToIndicator}>
            Сохранить
          </Button>
        </div>
      )}
    </Modal>
  );
}

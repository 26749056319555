import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { uniqWith } from 'lodash-es';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';
import s from './Tooltip.module.scss';

export const tooltipOptionsDefaults: Partial<Options> = {
  strategy: 'fixed',
  placement: 'top',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 0]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
        tether: false,
        altAxis: true
      }
    }
  ]
};

export interface TooltipClasses {
  root?: string;
  content?: string;
  arrow?: string;
}

export interface TooltipProps {
  className?: string;
  classes?: TooltipClasses;
  targetNode: Element | null;
  showArrow?: boolean;
  tooltipOptions?: Partial<Options>;
  children?: React.ReactNode;
}

export const Tooltip = ({
  className,
  classes,
  targetNode,
  showArrow = true,
  tooltipOptions,
  children
}: TooltipProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const options = useMemo(() => {
    if (!tooltipOptions) return tooltipOptionsDefaults;

    const { modifiers, ...restOptions } = tooltipOptions;
    const { modifiers: defaultModifiers, ...restOptionsDefault } = tooltipOptionsDefaults;

    // [{ name: 'A' }, { name: 'B' }, { name: 'A' }] => [{ name: 'A' }, { name: 'B' }]
    const modArray = uniqWith(
      [...(modifiers || []), ...(defaultModifiers || [])],
      (mod1, mod2) => mod1.name === mod2.name
    );

    if (showArrow && arrowElement) {
      modArray.push({
        name: 'arrow',
        options: { element: arrowElement }
      });
    }

    return {
      ...restOptionsDefault,
      ...restOptions,
      modifiers: modArray
    };
  }, [tooltipOptions, showArrow, arrowElement]);

  const { styles, attributes } = usePopper(targetNode, popperElement, options);

  return (
    <div
      ref={setPopperElement}
      className={clsx(s.Tooltip, className, classes?.root)}
      style={styles.popper}
      {...attributes.popper}>
      <div className={clsx(s.Tooltip__content, classes?.content)}>{children}</div>
      {showArrow && (
        <div
          ref={setArrowElement}
          className={clsx(s.Tooltip__arrow, classes?.arrow)}
          style={styles.arrow}
          {...attributes.arrow}
        />
      )}
    </div>
  );
};

import React, { useCallback, useMemo, useReducer } from 'react';
import clsx from 'clsx';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { PageError } from 'components/PageError';
import { PageLoader } from 'components/PageLoader';
import { GraphIcon, ListIcon, TabBar } from 'components/TabBar';
import { PageHeader } from 'componentsL/PageHeader';
import { DownloadsBlock } from 'features/DownloadsBlock';
import { IndicatorsTable } from 'features/IndicatorsTable';
import { StatisticsBlock } from 'features/StatisticsBlock';
import { CardFeedbackButton } from 'routes/indicatorL/_components/CardFeedbackButton';
import { basename } from 'store/config';
import {
  ExcelDataSourceEnum,
  RatingFeedbackScope,
  RatingCommentScopeEnum,
  RegionIndicatorType,
  useRegionPageQuery
} from 'store/graphql';
import { regionEfficiencyGroups } from 'utils/ratingGroups';
import Card from './Card';
import s from './styled.module.scss';

type ViewMode = 'graph' | 'table';

export function RegionPage() {
  const { regionId } = useParams();
  const [searchParams] = useSearchParams();
  // ID индикаторов, которым были добавлены обращения на странице
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const indicatorsWithFeedback = useMemo<Set<number>>(() => new Set(), [regionId]);
  const [, forceUpdate] = useReducer((i) => i + 1, 0);
  const mode = (searchParams.get('mode') || 'table') as ViewMode;

  const location = useLocation();

  const { loading, error, data, refetch } = useRegionPageQuery({
    variables: {
      ids: [Number(regionId)] as number[]
    }
  });
  const prevDateUpdate = data?.rating.regionRating.regions[0].previousUpdateDate;
  const region = data?.rating.regionRating.regions[0];

  const handleFeedbackSent = useCallback(
    (indicatorId: number | void) => {
      if (indicatorId !== void 0) {
        indicatorsWithFeedback.add(indicatorId);
        forceUpdate();
      }
    },
    [forceUpdate, indicatorsWithFeedback]
  );

  if (loading) return <PageLoader />;
  if (!!error || !region) return <PageError />;

  const indicatorsWithoutGroup = region.indicators.filter(({ groupField }) => !groupField);

  const indicatorsByEpgu = region.indicators.filter(({ groupField }) => groupField === 'epgu');

  const accessToIndicatorsFeedback = region.indicators.filter((i) => i.allowedToFeedback).length > 0;
  const accessToTotalFeedback = region.allowedToFeedback;
  const accessToFeedback = accessToTotalFeedback || accessToIndicatorsFeedback;

  const title =
    region.regionName.length <= 32 ? `Оперативный рейтинг РРЦТ ${region.regionName}` : `Оперативный рейтинг РРЦТ`;
  const titleSecondRow = region.regionName.length > 32 ? region.regionName : undefined;

  return (
    <div>
      <PageHeader
        backTo={location.key !== 'default' ? 'prevPage' : '/regions'}
        title={title}
        titleSecondRow={titleSecondRow}
        slotRight={
          <div className={clsx(s.Raw, s.Buttons)}>
            <div className={s.Buttons__group}>
              <DownloadsBlock
                links={[
                  {
                    text: 'Методика',
                    href: encodeURI(`${basename}/uploads/Методика_оперативного_рейтинга_РРЦТ_2024.pdf`)
                  }
                ]}
                excel={{
                  dataSource: ExcelDataSourceEnum.Region,
                  entityId: region.regionId.toString(),
                  text: 'Расчет'
                }}
              />
            </div>

            {accessToFeedback && (
              <div className={s.Buttons__group}>
                <CardFeedbackButton
                  scope={RatingFeedbackScope.Region}
                  scopeId={region.regionId}
                  onFeedbackSent={handleFeedbackSent}
                />
              </div>
            )}

            <div className={s.Buttons__group}>
              <TabBar
                items={[
                  { icon: GraphIcon, to: `/region/${regionId}?mode=graph`, active: mode === 'graph' },
                  { icon: ListIcon, to: `/region/${regionId}?mode=table`, active: mode === 'table' }
                ]}
              />
            </div>
          </div>
        }
      />

      <StatisticsBlock
        efficiencyGroup={region.ratingGroup}
        efficiencyGroupText={regionEfficiencyGroups[region.ratingGroup].text}
        degree={region.degree}
        pointsTotal={region.pointsLast}
        pointsMax={region.pointsMax}
        pointsDynamic={region.dynamic}
        lastPeriodDate={prevDateUpdate}
        indicatorsCount={region.indicators.length}
        rrct={region.rrct}
        representative={region.representatives[0]?.fullName ?? null}
        commentsControl={{
          scope: RatingCommentScopeEnum.Region,
          scopeId: region.regionId,
          scopeName: region.regionName,
          comments: region.publishedComments,
          draft: region.commentDraft,
          refetch: refetch
        }}
        rctControl={{
          scopeId: region.regionId,
          scopeName: region.regionName,
          refetchScopeData: refetch,
          rct:
            region.rrct && region.rrctUserId
              ? {
                  fullName: region.rrct,
                  name: region.rrct.split(' ')[1] ?? '',
                  surname: region.rrct.split(' ')[0] ?? '',
                  patronymic: region.rrct.split(' ')[2] ?? '',
                  userId: region.rrctUserId,
                  year: region.appointmentYear
                }
              : undefined,
          secondPersons: region.representatives.map((r) => ({ ...r }))
        }}
      />

      {mode === 'table' && (
        <div className={s.TableWrap}>
          <IndicatorsTable
            rows={region.indicators.map((value) => ({
              indicatorName: value.name,
              value: value.pointsTotal,
              valueDynamic: value.dynamic,
              successMark: value.successMark,
              alertMark: value.alertMark,
              indicatorProgressGroup: value.progressGroup,
              valueMax: value.pointsMax,
              linkTo: `/region/${region?.regionId}/indicator/${value.indicatorId}`,
              availableForDetails: value.availableForDetails,
              hasFeedback: value.haveFeedback || indicatorsWithFeedback.has(value.indicatorId),
              hasRecommendations: value.haveRecommendations
            }))}
          />
        </div>
      )}

      {mode === 'graph' && (
        <>
          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(0, 3).map((indicator) => (
              <div className={s.Raw_item} key={indicator.indicatorId}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card linkTo={`indicator/${indicator.indicatorId}`} indicator={indicator as RegionIndicatorType} />
                </div>
              </div>
            ))}
          </div>

          {indicatorsByEpgu.length > 0 && (
            <div key={'ЕПГУ'} className={s.Paper}>
              <h4 className={s.GroupName}>{'ЕПГУ'}</h4>
              <div className={s.Raw}>
                {indicatorsByEpgu.map(({ indicatorId, ...indicator }: any, index) => (
                  <div key={'chart-' + index} className={s.Raw_item}>
                    <Card linkTo={`indicator/${indicator.indicatorId}`} indicator={indicator} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(3, indicatorsWithoutGroup.length).map((indicator) => (
              <div key={indicator.indicatorId} className={s.Raw_item}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card linkTo={`indicator/${indicator.indicatorId}`} indicator={indicator as RegionIndicatorType} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

import { useCallback, useEffect } from 'react';
import { useLocalStorage as useLocalStorageReactUse, usePrevious, useUpdateEffect } from 'react-use';
import { tryParseJSON } from '../object';
export function useLocalStorage(key, initialValue, options) {
    const [lsData, setLsData, removeLsData] = useLocalStorageReactUse(key, initialValue, options);
    const prevLsData = usePrevious(lsData);
    const handleLSChange = useCallback((e) => {
        if (e.key === key) {
            const itemStr = e.newValue;
            const item = (tryParseJSON(itemStr) || itemStr);
            setLsData(item || undefined);
        }
    }, [key, setLsData]);
    useUpdateEffect(() => {
        const prevStr = JSON.stringify(prevLsData);
        const newStr = JSON.stringify(lsData);
        if (prevStr === newStr) {
            return;
        }
        const event = new StorageEvent('storage', {
            key,
            oldValue: JSON.stringify(prevLsData),
            newValue: JSON.stringify(lsData)
        });
        window.dispatchEvent(event);
    }, [key, prevLsData, lsData]);
    useEffect(() => {
        window.addEventListener('storage', handleLSChange);
        return () => {
            window.removeEventListener('storage', handleLSChange);
        };
    }, [handleLSChange]);
    return [lsData, setLsData, removeLsData];
}

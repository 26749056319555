import React from 'react';
import clsx from 'clsx';
import { IndicatorMethodologyType } from 'store/graphql';
import { CardDownloadsBlock } from './CardDownloadBlock';
import { CardDownloadButton } from './CardDownloadButton';
import s from './Card.module.scss';

export interface ICardProps {
  title: string;
  additionalInfo?: string | React.ReactNode;
  descriptor?: string;
  download?: string;
  downloadExcel?: string;
  downloadPath?: string;
  useDownloadBlock?: boolean;
  headerControls?: React.ReactNode;
  children: React.ReactNode;
  isChart?: boolean;
  isTable?: boolean;
  style?: React.CSSProperties;
  methodologies?: IndicatorMethodologyType[];
  indicatorId?: number;
}

export function Card({
  title,
  additionalInfo,
  descriptor,
  download,
  downloadExcel,
  downloadPath,
  useDownloadBlock,
  headerControls,
  children,
  style,
  isChart,
  isTable,
  methodologies,
  indicatorId
}: ICardProps) {
  const downloadBlockPublicLinks: undefined | { filename?: string | null; path?: string | null; label: string }[] =
    useDownloadBlock ? [] : undefined;
  if (downloadBlockPublicLinks && downloadExcel) {
    downloadBlockPublicLinks.push({
      label: 'Расчет',
      path: `${downloadPath}/excel`,
      filename: downloadExcel
    });
  }
  if (downloadBlockPublicLinks && download && (!methodologies || methodologies?.length === 0)) {
    downloadBlockPublicLinks.push({
      label: 'Методика',
      path: downloadPath,
      filename: download
    });
  }

  return (
    <section className={clsx(s.Card, isChart && s.Card__isChart)} style={style}>
      <header className={s.Card__header}>
        <div className={s.Card__meta}>
          <h3 className={s.Card__heading}>{title}</h3>
          {additionalInfo && <p className={s.Card__descriptor}>{additionalInfo}</p>}
          {descriptor && <p className={s.Card__descriptor}>{descriptor}</p>}
        </div>
        <div className={s.Card__controls}>
          {!useDownloadBlock && (download || !!methodologies?.length) && (
            <CardDownloadButton
              filename={download}
              path={downloadPath}
              disabled={download === '#' && !methodologies?.length}
              methodologies={methodologies}
              indicatorId={indicatorId}
            />
          )}
          {useDownloadBlock && (
            <CardDownloadsBlock
              methodologies={methodologies}
              indicatorId={indicatorId}
              links={downloadBlockPublicLinks!}
            />
          )}
          {headerControls && <div className={s.Cart__controlsDivider} />}
          {headerControls && <>{headerControls}</>}
        </div>
      </header>
      {isChart || isTable ? <div className={clsx(isTable && s.Card__isTable)}>{children}</div> : children}
    </section>
  );
}

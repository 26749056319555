import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ApolloError, useApolloClient } from '@apollo/client';
import { Optional } from '@digital-gov/ui-utils';
import { useAlertLocalStorage } from 'hooks/useAlert';
import { IndicatorFeedback } from 'routes/indicatorL/_components/IndicatorFeedback';
import {
  DepartmentType,
  RatingFeedbackScope,
  RegionType,
  RequestDepartmentsDocument,
  RequestDepartmentsQuery,
  RequestDepartmentsQueryVariables,
  RequestRegionsDocument,
  RequestRegionsQuery,
  RequestRegionsQueryVariables
} from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { Alert, AlertProps } from '../Alert';
import s from './AlertRatingFeedback.module.scss';

interface Available {
  feedback: boolean;
  proposal: boolean;
}

interface DataResult {
  loading: boolean;
  error: ApolloError | void;
  available: Available;
}

function normalize(data: RegionType | DepartmentType): Available {
  return {
    proposal: data.allowedToFeedback,
    feedback: data.indicators.some((item) => item.allowedToFeedback)
  };
}

function useData(
  departmentId: Optional<number> | number | void,
  regionsId: Optional<number> | number | void,
  departmentPublished?: boolean | null
): DataResult {
  const apollo = useApolloClient();
  const [result, setResult] = useState<DataResult>({
    loading: true,
    error: void 0,
    available: {
      feedback: false,
      proposal: false
    }
  });

  const getData = useCallback(async () => {
    try {
      if (departmentPublished && departmentId !== void 0 && departmentId !== null) {
        const { data } = await apollo.query<RequestDepartmentsQuery, RequestDepartmentsQueryVariables>({
          query: RequestDepartmentsDocument,
          variables: {
            departmentId: departmentId
          }
        });

        setResult({
          loading: false,
          error: void 0,
          available: normalize(data.rating.departmentRating.departments[0] as DepartmentType)
        });
      } else if (regionsId !== void 0 && regionsId !== null) {
        const { data } = await apollo.query<RequestRegionsQuery, RequestRegionsQueryVariables>({
          query: RequestRegionsDocument,
          variables: {
            regionId: regionsId
          }
        });

        setResult({
          loading: false,
          error: void 0,
          available: normalize(data.rating.regionRating.regions[0] as RegionType)
        });
      }
    } catch (err: ApolloError | any) {
      setResult({
        loading: false,
        error: err,
        available: {
          feedback: false,
          proposal: false
        }
      });
    }
  }, [apollo, departmentId, regionsId]);

  useEffect(() => {
    return () => apollo.stop();
  }, [apollo, departmentId, regionsId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return result;
}

export const AlertRatingFeedback = (props: AlertProps) => {
  const navigate = useNavigate();
  const { departmentId, departmentPublished, regionId } = useProfile();
  const { loading, error, available } = useData(departmentId, regionId, departmentPublished);

  const [alertVisible, setAlertVisibility] = useAlertLocalStorage('ratingFeedback');
  const [modalShown, setModalShown] = useState(false);
  const canOpenFeedback = useMemo(() => {
    return available.feedback || available.proposal;
  }, [available]);

  const handleShow = useCallback(() => {
    if (canOpenFeedback) {
      setModalShown(true);
      if (!!departmentId && departmentPublished) {
        navigate('/department/' + departmentId);
      } else {
        navigate('/region/' + regionId);
      }
    }
  }, [canOpenFeedback, navigate, regionId, departmentId, departmentPublished]);

  const handleClose = useCallback(() => setModalShown(false), [setModalShown]);

  if (loading || error || !canOpenFeedback) return null;
  return (
    <React.Fragment>
      {alertVisible && (
        <Alert {...props} className={s.AlertRatingFeedback} onClose={() => setAlertVisibility(false)}>
          Если у вас есть замечания и предложения по оперативному рейтингу РЦТ, воспользуйтесь{' '}
          <span className={clsx(s.AlertRatingFeedback__feedback)} onClick={handleShow}>
            формой обратной связи
          </span>
        </Alert>
      )}

      {modalShown && (
        <IndicatorFeedback
          feedbackAvailable={available.feedback}
          proposalAvailable={available.proposal}
          scope={!!departmentId ? RatingFeedbackScope.Department : RatingFeedbackScope.Region}
          scopeId={departmentId || regionId || 0}
          onClose={handleClose}
        />
      )}
    </React.Fragment>
  );
};

import React, { useCallback, useMemo, useReducer } from 'react';
import clsx from 'clsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { PageError } from 'components/PageError';
import { PageLoader } from 'components/PageLoader';
import { GraphIcon, ListIcon, TabBar } from 'components/TabBar';
import { PageHeader } from 'componentsL/PageHeader';
import { DownloadsBlock } from 'features/DownloadsBlock';
import { IndicatorsTable } from 'features/IndicatorsTable';
import { StatisticsBlock } from 'features/StatisticsBlock';
import { CardFeedbackButton } from 'routes/indicatorL/_components/CardFeedbackButton';
import { basename } from 'store/config';
import {
  DepartmentIndicatorType,
  ExcelDataSourceEnum,
  RatingCommentScopeEnum,
  RatingFeedbackScope,
  useDepartmentPageQuery
} from 'store/graphql';
import { departmentEfficiencyGroups } from 'utils/ratingGroups';
import Card from './Card';
import s from './styled.module.scss';

type ViewMode = 'graph' | 'table';

export function DepartmentPage() {
  const { departmentId } = useParams();
  const [searchParams] = useSearchParams();
  // ID индикаторов, которым были добавлены обращения на странице
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const indicatorsWithFeedback = useMemo<Set<number>>(() => new Set(), [departmentId]);
  const [, forceUpdate] = useReducer((i) => i + 1, 0);
  const mode = (searchParams.get('mode') || 'table') as ViewMode;

  const { loading, error, data, refetch } = useDepartmentPageQuery({
    variables: {
      ids: [Number(departmentId)]
    }
  });

  const department = data?.rating?.departmentRating.departments?.[0];

  const handleFeedbackSent = useCallback(
    (indicatorId: number | void) => {
      if (indicatorId !== void 0) {
        indicatorsWithFeedback.add(indicatorId);
        forceUpdate();
      }
    },
    [forceUpdate, indicatorsWithFeedback]
  );

  if (loading) return <PageLoader />;
  if (!!error || !department) return <PageError />;

  const indicatorsWithoutGroup = department.indicators.filter(({ groupField }) => !groupField);

  const indicatorsByEpgu = department.indicators.filter(({ groupField }) => groupField === 'epgu');

  const accessToIndicatorsFeedback = department.indicators.filter((i) => i.allowedToFeedback).length > 0;
  const accessToTotalFeedback = department.allowedToFeedback;
  const accessToFeedback = accessToTotalFeedback || accessToIndicatorsFeedback;

  const title =
    department.departmentName.length <= 32
      ? `Оперативный рейтинг ФРЦТ ${department.departmentName}`
      : `Оперативный рейтинг ФРЦТ`;
  const titleSecondRow = department.departmentName.length > 32 ? department.departmentName : undefined;
  return (
    <div>
      <PageHeader
        backTo={'/'}
        title={title}
        titleSecondRow={titleSecondRow}
        slotRight={
          <div className={clsx(s.Raw, s.Buttons)}>
            <div className={s.Buttons__group}>
              <DownloadsBlock
                links={[
                  {
                    text: 'Методика',
                    href: encodeURI(`${basename}/uploads/Методика_оперативного_рейтинга_ФРЦТ_2024.pdf`)
                  }
                ]}
                excel={{
                  dataSource: ExcelDataSourceEnum.Department,
                  entityId: department.departmentId.toString(),
                  text: 'Расчет'
                }}
              />
            </div>

            {accessToFeedback && (
              <div className={s.Buttons__group}>
                <CardFeedbackButton
                  scope={RatingFeedbackScope.Department}
                  scopeId={department.departmentId}
                  onFeedbackSent={handleFeedbackSent}
                />
              </div>
            )}

            <div className={s.Buttons__group}>
              <TabBar
                items={[
                  { icon: GraphIcon, to: `/department/${departmentId}?mode=graph`, active: mode === 'graph' },
                  { icon: ListIcon, to: `/department/${departmentId}?mode=table`, active: mode === 'table' }
                ]}
              />
            </div>
          </div>
        }
      />

      {departmentId && (
        <StatisticsBlock
          efficiencyGroup={department.ratingGroup}
          efficiencyGroupText={departmentEfficiencyGroups[department.ratingGroup].text}
          degree={department.degree}
          pointsTotal={department.pointsTotal}
          pointsMax={department.pointsMax}
          pointsDynamic={department.overallDynamic}
          lastPeriodDate={department.previousPeriodDate}
          indicatorsCount={department.indicators.length}
          frct={department.rct}
          viceChairman={department.viceChairmans[0]?.fullName ?? null}
          commentsControl={{
            scope: RatingCommentScopeEnum.Department,
            scopeId: department.departmentId,
            scopeName: department.departmentName,
            comments: department.publishedComments,
            draft: department.commentDraft,
            refetch: refetch
          }}
          rctControl={{
            scopeId: department.departmentId,
            scopeName: department.departmentName,
            refetchScopeData: refetch,
            rct:
              department.rct && department.rctUserId
                ? {
                    fullName: department.rct,
                    name: department.rct.split(' ')[1] ?? '',
                    surname: department.rct.split(' ')[0] ?? '',
                    patronymic: department.rct.split(' ')[2] ?? '',
                    userId: department.rctUserId,
                    year: department.appointmentYear
                  }
                : undefined,
            secondPersons: department.viceChairmans.map((v) => ({ ...v }))
          }}
        />
      )}

      {mode === 'table' && (
        <div className={s.TableWrap}>
          <IndicatorsTable
            rows={department.indicators.map((value) => ({
              indicatorName: value.name,
              value: value.pointsTotal,
              valueDynamic: value.dynamic,
              valueMax: value.pointsMax,
              successMark: value.successMark,
              alertMark: value.alertMark,
              indicatorProgressGroup: value.progressGroup,
              linkTo: `/department/${departmentId}/indicator/${value.indicatorId}`,
              availableForDetails: value.availableForDetails,
              hasFeedback: value.haveFeedback || indicatorsWithFeedback.has(value.indicatorId),
              hasRecommendations: value.haveRecommendations
            }))}
          />
        </div>
      )}

      {mode === 'graph' && (
        <>
          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(0, 3).map((indicator, i) => (
              <div className={s.Raw_item} key={i}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card
                    linkTo={`indicator/${indicator.indicatorId}`}
                    indicator={indicator as DepartmentIndicatorType}
                  />
                </div>
              </div>
            ))}
          </div>

          {indicatorsByEpgu.length > 0 && (
            <div className={`${s.Paper} ${s.Min_height}`}>
              <h4 className={s.GroupName}>{'ЕПГУ'}</h4>
              <div className={s.Raw}>
                {indicatorsByEpgu.map(({ indicatorId, ...indicator }: any, i) => (
                  <div className={s.Raw_item} key={i}>
                    <Card linkTo={`indicator/${indicatorId}`} indicator={indicator} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(3, indicatorsWithoutGroup.length).map((indicator, i) => (
              <div className={s.Raw_item} key={i}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card
                    linkTo={`indicator/${indicator.indicatorId}`}
                    indicator={indicator as DepartmentIndicatorType}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getAuthTarget, useAuth } from '@digital-gov/auth-apollo-store';
import { useScrollTopOnRouteChange } from '@digital-gov/ui-utils';
import { basename } from 'store/config';
import { UserLayout } from './_layouts/UserLayout';
import { DepartmentPage } from './department';
import { DepartmentsPage } from './departments';
import { FaqPage, RrctFaqPage, FrctFaqPage } from './faq';
import { departmentIndicatorRoutes, regionIndicatorRoutes } from './indicator';
import { DepartmentPageMvi, RegionPageMvi } from './indicatorsMvi';
import { LoginPage } from './login';
import { RegionPage } from './region';
import { RegionsPage } from './regions';
// import { FeedbackSurveyPage } from './feedbackSurvey';

export function AppRoutes() {
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();

  useScrollTopOnRouteChange();

  if (isLoggedIn()) {
    return (
      <Routes>
        <Route element={<UserLayout showPeriodSelectControl isLoggedIn />}>
          <Route path={'/'} element={<DepartmentsPage />} />
          <Route path={'/mvi/frct'} element={<DepartmentPageMvi />} />
          <Route path={'/department/:departmentId'}>
            <Route index element={<DepartmentPage />} />
            {departmentIndicatorRoutes}
          </Route>

          {/*Отключён по требованию МЦ: <Route path={'/feedback-survey/:questionIndex?'} element={<FeedbackSurveyPage />} />*/}
        </Route>

        <Route element={<UserLayout showPeriodSelectControl isLoggedIn scope={'rrct'} />}>
          <Route path={'/regions'} element={<RegionsPage />} />
          <Route path={'/mvi/rrct'} element={<RegionPageMvi />} />
          <Route path={'/region/:regionId'}>
            <Route index element={<RegionPage />} />
            {regionIndicatorRoutes}
          </Route>
        </Route>

        <Route element={<UserLayout isLoggedIn showPeriodSelectControl={false} />}>
          <Route path={'/faq'} element={<FaqPage />}>
            <Route index element={<Navigate to={'/faq/frct'} />} />
            <Route path={'frct'} element={<FrctFaqPage />} />
            <Route path={'rrct'} element={<RrctFaqPage />} />
          </Route>
        </Route>

        <Route path={'/auth'} element={<Navigate to={getAuthTarget().replace(basename, '')} replace />} />
        <Route path={'*'} element={<Navigate to={'/'} replace />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route element={<UserLayout showTopBar={false} />}>
          <Route path={'/login'} element={<LoginPage />} />
        </Route>

        <Route path={'/auth'} element={<Navigate to={'/login'} replace />} />
        <Route path={'*'} element={<Navigate to={`/login?target=${pathname}`} replace />} />
      </Routes>
    );
  }
}

import { useLocalStorage, useSessionStorage } from '@digital-gov/ui-utils';

export function useAlertLocalStorage(name: string) {
  const [LShidden, setLS, removeLS] = useLocalStorage(`alert-${name}`);

  const handleSetActive = (value: boolean) => {
    if (value) {
      removeLS();
    } else {
      setLS(true);
    }
  };

  return [!LShidden, handleSetActive] as const;
}

export function useAlertSessionStorage(name: string) {
  const [SShidden, setSS, removeSS] = useSessionStorage(`alert-${name}`);

  const handleSetActive = (value: boolean) => {
    if (value) {
      removeSS();
    } else {
      setSS(true);
    }
  };

  return [!SShidden, handleSetActive] as const;
}
